<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
    <div class="col-12" style="padding: 0 !important;">
        <MarketHeader :data="$route.params.name"></MarketHeader>
        <!-- <div class="market-main-sub-tabs w-scroll-style">
            <div v-for="(item, index) in marketNavPage" :key="index" v-if="!item.hide" :class="'market-main-sub-tab-item ' + (item.name == tabName ? 'active' : '') ">
                 <router-link :to="{name: item.name, params: { name: $route.params.name }, }">
                    <i :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                </router-link>
            </div>
        </div> -->
    </div>
    <MarketHistory></MarketHistory>
</div>
</template>

<style>
    .clearfix.tgju-widget-forms input {
        height: 40px !important;
        border: 1px solid #ccc !important;
        box-shadow: unset !important;
        margin-left: 2px;
    }
    .filter-wrapper .search-box input {
        width: 250px;
    }

    @media (max-width: 1220px) {

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(1)::before {
            content: 'بازگشایی' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(2)::before {
            content: 'کمترین' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(3)::before {
            content: 'بیشترین' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(4)::before {
            content: 'پایانی' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(5)::before {
            content: 'میزان تغییر' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(6)::before {
            content: 'درصد تغییر' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(7)::before {
            content: 'تاریخ / میلادی' !important;
        }
        
        .tgju-widget .table-mobile-grid tbody tr td:nth-child(8)::before {
            content: 'تاریخ / شمسی' !important;
        }
    }

    #columns, #columns .column ul {
        height: 490px!important;
    }
    #columns {
    border-radius: 6px;
    border: 0;
    }
    #columns {
        min-height: 490px;
    }
    .diagrams-wrapper .fa-times {
        font-size: 30px;
    }
    .profile-history .ui-datepicker-trigger {
        display: none;
    }
    
    #stocks-table-data .dataTables_wrapper .row:last-child {
        display: block;
    }

    .stocks-profile .dataTables_wrapper .dataTables_info {
        position: relative;
        z-index: 1;
    }
    .tv-select-btn{
        float: left;
    }
    .tv-select-btn .tv-select{
        background: #ef8331;
        border-color: #ef8331;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 8px;
        margin-left: 15px;
        margin-top: 13px;
        padding-top: 10px;
    }
    @media(max-width:1220px) {
        .tv-select-btn{
            width: 100%;
            margin-bottom: 20px;
            padding: 0 20px;
        }
        .tv-select-btn .tv-select{
            margin-top: 0;
            padding-top: 10px;
            width: 100%;
        }
    }
</style>
<script>
// این ویو برای ماژول  بازار  پروفایل شاخص ، بخش اولیه مورد استفاده قرار میگیرد
import MarketHistory from "@/components/Mobile/Market/Profile/History";
import marketNavPage from "@/assets/static_market_nav_page";
import MarketHeader from '@/components/Mobile/Market/Profile/Header';

export default {
    name: "marketHistory",
    components: {
        MarketHistory,
        MarketHeader,
    },
    data: function () {
        return {
            title: "--",
            symbol: "--",
            marketNavPage,
            tabName:'MarketHistory',
        };
    },
    mounted() {
    },
};
</script>
